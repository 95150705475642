import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; 
import Home from "./components/Home";
import VerificationCertification from "./components/VerificationCertification";
import RequestCertification from "./components/RequestCertification";
import WhyUs1 from "./components/WhyUs1";
import Header from './components/Header';
import dotenv from "dotenv";
import AppFooter from './components/homecomponents/AppFooter';

dotenv.config()

function App() {
  return (
      <Router>
        <Header></Header>
        <Switch>
          <Route exact path="/">
            <Home></Home>
          </Route>
          <Route path="/verificationcert">
            <VerificationCertification></VerificationCertification>
          </Route>
          <Route path="/requestcert">
            <RequestCertification></RequestCertification>
          </Route>
          <Route path="/ourmission">
            <WhyUs1></WhyUs1>
          </Route>
        </Switch>
        <AppFooter></AppFooter>
      </Router>
  );
}

export default App;
