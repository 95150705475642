const messages = {
    en: {
        translations: {
            header: {
                certVer: 'Certification Verification',
                reqCert: 'Request Certifications',
                whyUs: 'Our Mission',
            },
            home: {
                section1: {
                    title: 'International Provider for Grading of Trading Cards',
                    learnmore: 'Learn more'
                },
                section2: {
                    title1: 'Prices',
                    text1: 'Only 10 CHF per card',
                    title2: 'Subgrading',
                    text2: 'Subgrading available for an aditional 5 CHF',
                    title3: 'Shipping',
                    text3: 'Return shipping in less than 14 days!',
                    title4: 'Swiss VAT Friendly',
                    text4: 'No Cross-border VAT costs for Switzerland',
                },
                section3: {
                    title: 'ABOUT OUR WORK',
                    text1: 'Ultrasonic technology',
                    text2: 'Online database for certification number verification',
                    text3: 'Fair price',
                    text4: 'All orders can also include subgradings',
                    text5: 'For all cards and all languages (TCG+OCG)',
                    text6: 'Made in Switzerland',
                    btn: 'Request Certifications',
                }
            },
            footer: {
                title1: 'Legal',
                text: 'Terms and Privacy',
                title2: 'Contacts',
            },
            certVer: {
                title1: 'Item Information',
                title2: 'Certification Verification',
                input: 'Enter Serial Number',
                btn: 'Verify',
                alert1: 'Serial Number must be a number!',
                alert2: 'Serial Number doesn\'t exists!',
            },
            reqCer: {
                title: 'Request Certification',
                ncards: 'Nº Cards(10CHF/Card)',
                subgrading: 'Subgrading(5CHF/Card)',
                price: 'Price',
                firstname: 'First Name',
                lastname: 'Last Name',
                address: 'Address',
                zipcity: 'ZIP and City (8000 - Zurich)',
                country: 'Country',
                email: 'Email',
                phonenumber: 'Phone Number',
                alertsuccess: 'Request Certification submitted with success! You will be contacted by email soon.',
                alerterror: 'We apologize but we weren\'t able to save the request. Please try again later or contact us diretly to csgsgrading@gmail.com',
                btn: 'Submit',

            },
            whyUs: {
                title: 'Our mission is to help collectors and investors!',
                investor_title: 'Investor',
                investor_text: 'Ideal for investors as our cost per certification is only 10 CHF instead of 50, 200 or 300 USD per card. Each collector will always be able to profit after our certification of authenticity and condition. Is it worth paying a high price for certification and having the risk of a non-profit from a possible resale?',
                collector_title: 'Collector',
                collector_text: 'We keep your collection safe as our slabs protect the cards from ultraviolet radiation, anti-abrasion, temperature and aging. Our settlement is carried out with the latest ultrasonic sealing technology that leaves no residue. The slabs offer waterproof protection and do not let moisture inside.',
                final_text: 'We also believe that cards should not be rated based on what character is on the card, what value the card is, or whether a reviewer has a good day or a bad day.  The cards should only be rated according to the condition of the card itself.  For this reason we are developing unique software that detects edges for sharpness, corners for sharpness, centering for balance and surfaces without blemishes.  We believe that we will rate your card 100% accurately.',
            }
        }
    }

}

export { messages }