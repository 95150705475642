const messages = {
    de: {
        translations: {
            header: {
                certVer: 'Zertifizierungsprüfung',
                reqCert: 'Zertifizierungen anfordern',
                whyUs: 'Unsere Aufgabe'
            },
            home: {
                section1: {
                    title: 'Internationaler Anbieter für die Bewertung von Sammelkarten',
                    learnmore: 'Weitere Informationen'
                },
                section2: {
                    title1: 'Preise',
                    text1: 'Nur 10 CHF pro Karte',
                    title2: 'Abstufung',
                    text2: 'Subgrading für zusätzliche 5 CHF möglich',
                    title3: 'Versand',
                    text3: 'Rückversand in weniger als 14 Tagen!',
                    title4: 'Swiss VAT Friendly',
                    text4: 'Keine grenzüberschreitenden Mehrwertsteuerkosten für die Schweiz',
                },
                section3: {
                    title: 'ÜBER UNSERE ARBEIT',
                    text1: 'Ultraschalltechnologie',
                    text2: 'Online-Datenbank zur Überprüfung der Zertifizierungsnummer',
                    text3: 'fairer Preis',
                    text4: 'Alle Aufträge können auch Unterteilungen enthalten',
                    text5: 'Für alle Karten und alle Sprachen (TCG+OCG)',
                    text6: 'Made in Switzerland',
                    btn: 'Zertifizierungen anfordern',
                }
            },
            footer: {
                title1: 'Recht',
                text: 'Nutzungsbedingungen',
                title2: 'Kontakte',
            },
            certVer: {
                title1: 'Artikelinformationen',
                title2: 'Zertifizierungsprüfung',
                input: 'Seriennummer eingeben',
                btn: 'Überprüfen',
                alert1: 'Seriennummer muss eine Nummer sein!',
                alert2: 'Seriennummer existiert nicht!',
            },
            reqCer: {
                title: 'Zertifizierung anfordern',
                ncards: 'Anzahl Karten (10CHF/Karte)',
                subgrading: 'Abstufung (5CHF/Karte)',
                price: 'Preis',
                firstname: 'Vorname',
                lastname: 'Nachname',
                address: 'Adresse',
                zipcity: 'PLZ und Stadt (8000 - Zürich)',
                country: 'Land',
                email: 'Email',
                phonenumber: 'Telefonnummer',
                alertsuccess: 'Zertifizierung erfolgreich eingereicht! Sie werden in Kürze per E-Mail kontaktiert.',
                alerterror: 'Wir entschuldigen uns, aber wir konnten die Anfrage nicht speichern.  Bitte versuchen Sie es später noch einmal oder kontaktieren Sie uns direkt an csgsgrading@gmail.com',
                btn: 'Senden',

            },
            whyUs: {
                title: 'Unsere Mission ist es, Sammlern und Investoren zu helfen!',
                investor_title: 'Investor',
                investor_text: 'Ideal für Investoren, da unsere Kosten pro Zertifizierung nur 10 CHF statt 50, 200, 300 USD pro Karte kosten, jeder Sammler kann von unserer Echtheits- und Zustandsbestätigung immer profitieren. Lohnt es sich einen hohen Preis für die Zertifizierung zu zahlen und das Risiko einzugehen aus einem möglichen Weiterverkauf keinen Gewinn zu machen?',
                collector_title: 'Sammler',
                collector_text: 'Bringen Sie Ihre Sammlung in Sicherheit, denn unser Slab schützt die Karten vor UV-Strahlung, Abnutzung, Temperatur, sowie Veralterung. Unsere Versiedelung erfolgt mit modernster Ultraschall-Siegeltechnologie, die keine Rückstände hinterlässt. Die Slabs bieten wasserdichten Schutz und lassen keine Feuchtigkeit nach Innen.',
                final_text: 'Wir sind auch der Meinung, dass Karten nicht danach bewertet werden sollten, welcher Charakter auf der Karte zu sehen ist, welchen Wert die Karte hat oder ob ein Bewerter einen guten oder schlechten Tag hat.  Die Karten sollten ausschliesslich nach dem Zustand der Karte selbst bewertet werden. Aus diesem Grund entwickeln wir eine einzigartige Software, die Kanten für Schärfe, Ecken für Schärfe, Zentrierung für Balance und Oberflächen ohne Makel erkennt. Wir sind der Meinung, dass wir Ihre Karte zu 100% genau bewerten werden.',
            }
        }
    }
}

export { messages }