import React from 'react';
import ProductValues from './homecomponents/ProductValues';
import ProductHowItWorks from './homecomponents/ProductHowItWorks';
import ProductHero from './homecomponents/ProductHero';

export const Home = () => {
    return (
        <div id="home">
            <ProductHero></ProductHero>
            <ProductValues></ProductValues>
            <ProductHowItWorks></ProductHowItWorks>
        </div>
    )
}

export default Home;