import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Typography from './Typography';
import './AppFooter.css';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import { i18n } from '../../translate/translations';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#fbfbfb',
  },
  container: {
    marginTop: theme.spacing(3),
    display: 'flex',
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: 'flex',
  },
  icon: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.warning.main,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  list: {
    margin: 0,
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
}));

export default function AppFooter() {
  const classes = useStyles();

  return (
    <Typography component="footer" className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5} style={{margin:"0"}}>
          <Grid item xs={6} sm={4} md={3}>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              className={classes.iconsWrapper}
              spacing={2}
            >
              <Grid item className={classes.icons}>
                <a href="https://www.facebook.com/profile.php?id=100071855959489" className={classes.icon}>
                  <FacebookIcon style={{fill:"black"}}></FacebookIcon>
                </a>
                <a href="https://www.instagram.com/csgsgrading/" className={classes.icon}>
                  <InstagramIcon style={{fill:"black"}}></InstagramIcon>
                </a>
              </Grid>
              <Grid item>
                <Typography variant="h7" marked="left" gutterBottom tag="CSGS Grading Footer">
                    CSGS
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography variant="h6" marked="left" gutterBottom tag="CSGS Grading Footer Terms and Policy">
              {i18n.t('footer.title1')}
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <Link href="/">{i18n.t('footer.text')}</Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography variant="h6" marked="left" gutterBottom tag="CSGS Grading Footer Contacts">
              {i18n.t('footer.title2')}
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <Typography variant="h7" marked="left" gutterBottom tag="CSGS Grading Footer Email">
                csgsgrading@gmail.com
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}