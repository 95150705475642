import axios from "axios";

export async function submitRequestCert(request) {

    //console.log(request)

    // Insert Certification 
    var config = {
        method: 'post',
        url: process.env.REACT_APP_URL + '/api/orders/insertcertification',
        headers: { 
            'Content-Type': 'application/json'
        },
        data: request
    };

    let res;
    let order;

    await axios(config)
    .then(function (response) {
        //console.log(response);
        if(response.data !== 0) {
            //console.log(response.data)
            order = response.data;
            //sendEmails(response.data);
            res = "success";
        } else {
            res = "error";
        }
        
    })
    .catch(function (error) {
        console.log(error);
        res = "error";
    });


    // Send Emails
    if(res === "success") {

        res = "error"

        let emailsToSend = sendEmails(order);

        config = {
            method: 'post',
            url: process.env.REACT_APP_URL + '/api/emails/sendemails',
            headers: { 
                'Content-Type': 'application/json'
            },
            data: emailsToSend
        };

        await axios(config)
        .then(function (response) {
            //console.log(response.data);
            if(response.data === 200) {
                res = "success";
            } else {
                res = "error";
            }
            
        })
        .catch(function (error) {
            console.log(error);
            res = "error";
        });
    }

    //console.log("return")
    return res
}

function sendEmails(order) {

    let email = order.email;
    let order_id = order._id;

    //let bodyToClient = "<br>Dear costumer, we thank you for trusting us!<br><br>Your order number is " + order_id + ".<br><br>We ask that you send us your cards with maximum security according the following instructions:<br> 1) For your cards protection, put each card in a single protection cover.<br>2) Next, insert each card in a slab card. Make sure we can safely remove the card.<br>3) Then, surround each card with with paperboard and secure the pieces of paperboard with rubber bands. Make sure the rubber bands are not too tight as they may damage the cards. They should be just tight enough to hold the paperboard pieces together.<br>4) Protect the cards with packaging material (plastics, for example).<br>5) Package everything in a resilient cardboard box making sure that the cards are not very tight.<br>6) Put a note with your name and email in the box.<br>7)Send your cards with tracking number to:<br> José Costa<br> Rietgrabenstrasse 52<br> 8152 - Opfikon<br> Switzerland<br>8) Make your payment using PayPal ( www.paypal.com ) " + order.price + " chf + 7 chf (return shipping costs) to csgsgrading@gmail.com <br><br>With the best regards,<br>José Costa<br>Schweizer Grading Service";
    let bodyToClient = "<br>Dear costumer, we thank you for trusting us!<br><br>Your order number is " + order_id + " and we expect to contact you soon with instructions on how you can send us your cards and on how to proceed with the payment.<br><br>With the best regards,<br>CSGS<br>Schweizer Grading Service";

    const msgToClient = {
        to: email, // Change to your recipient
        from: 'csgsgrading@gmail.com', // Change to your verified sender
        subject: 'CSGS Certification Request',
        html: bodyToClient
    }

    const msgToCsgs = {
        to: 'csgsgrading@gmail.com', // Change to your recipient
        from: 'csgsgrading@gmail.com', // Change to your verified sender
        subject: 'CSGS Certification Request',
        html: bodyToClient
    }

    const emails = {
        client: msgToClient,
        server: msgToCsgs
    }

    return emails;

}