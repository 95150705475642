import React from 'react';
import WhyProductHowItWorks from './homecomponents/WhyProductHowItWorks';

export const WhyUs1 = () => {
    return (
        <div id="whyus">
            <WhyProductHowItWorks></WhyProductHowItWorks>
        </div>
    )
}

export default WhyUs1;