import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from './Typography';
//import { Typography } from '@material-ui/core';
import './HomeComponents.css'
import './WhyProductHowItWorks.css'
import { i18n } from '../../translate/translations';

const styles = (theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#ffffff',
    overflow: 'hidden',
    backgroundPosition: "10% 100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "150vh"
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  title: {
    marginBottom: theme.spacing(14),
  },
  subtitle: {
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(2),
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 350,
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -510,
    left: -75,
  },
  button: {
    marginTop: theme.spacing(8),
  },
});

function WhyProductHowItWorks(props) {
  const { classes } = props;

  return (
    <section className={classes.root} id="sectionHIW">
      <Container className={classes.container}>
        <Typography variant="h4" className={classes.title} id="titleHowitworks" component="h1" tag="CSGS Grading Our mission" >
          {i18n.t('whyUs.title')}
        </Typography>
        <Typography variant="h4" marked="center" className={classes.subtitle} tag="CSGS Grading Investor">
          {i18n.t('whyUs.investor_title')}
        </Typography>
        <Typography variant="h6" component="h6" tag="CSGS Grading Ideal for investors if trading card certification" >
          {i18n.t('whyUs.investor_text')}
        </Typography>
        <Typography variant="h4" marked="center" className={classes.subtitle} tag="CSGS Grading Collector" >
          {i18n.t('whyUs.collector_title')}
        </Typography>
        <Typography variant="h6" component="h6" tag="CSGS Grading Ideal for collectors if trading card certification"  >
          {i18n.t('whyUs.collector_text')}
        </Typography>
        <div>
          <Grid container spacing={50}>
          <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number}> </div>
                <img className={classes.image}
                alt="CSGS Grading Office"
              />
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number}> </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number}> </div>
                <img className={classes.image}
                alt="CSGS Grading Trading Card Cetificated"
              />
              </div>
            </Grid>
          </Grid>
        </div>
        <Typography variant="h6" id="whyUs1" component="h6" tag="CSGS Grading Trading Card Certification" >
          {i18n.t('whyUs.final_text')}
        </Typography>
      </Container>
    </section>
  );
}

WhyProductHowItWorks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WhyProductHowItWorks);