import React from 'react';
import { useState } from 'react';
import { TextField, FormControlLabel, Checkbox, Button } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import './RequestCertification.css'
import MuiPhoneNumber from 'material-ui-phone-number'
import { submitRequestCert } from './functions/submitRequestCert';
import { i18n } from '../translate/translations';


export const RequestCertification = () => {
    const [numCards, setNumCards] = useState('');
    const [subgrading, setSubgrading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [cityZip, setCityZip] = useState('');
    const [country, setCountry] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [msg, setMsg] = useState('');
    const [priceTotal, setPriceTotal] = useState('0 CHF');
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [alertError, setAlertError] = useState(false);

    var subgradingPrice = process.env.REACT_APP_SUBGRADINGPRICE;
    var cardPrice = process.env.REACT_APP_CARDPRICE; 

    const handleChangeSubgrading = (e) => {
        setSubgrading(e.target.checked)

        //console.log(e.target.checked);
        //console.log(subgradingPrice);
        //console.log(cardPrice);

        let price = parseInt(numCards);

        if(e.target.checked) {
            price = price * (parseInt(subgradingPrice) + parseInt(cardPrice));
        } else {
            price = price * parseInt(cardPrice);
        }
        price = price + " CHF";
        setPriceTotal(price)
        //console.log(`TotalPrice ${price} for ${numCards} cards`)
    }

    function handleOnChange(value) {
        //console.log("Phone = " + value)
        setPhone(value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setAlertSuccess(false);
        setAlertError(false);

        let dataRequest = {
            "numCards": e.target.numCards.value,
            "subgrading": e.target.subgrading.value,
            "firstName": e.target.firstName.value,
            "lastName": e.target.lastName.value,
            "address": e.target.address.value,
            "cityZip": e.target.cityZip.value,
            "country": e.target.country.value,
            "email": e.target.email.value,
            "phone": e.target.phone.value,
            "msg": e.target.msg.value,
            "price": e.target.price.value
        };

        let response = await submitRequestCert(dataRequest);

        //console.log(response);
        
        if(response === "success") {
            setAlertSuccess(true);
        } else {
            //setAlertError(true);
            // problema ignorado nao resolvido: gridsender nao esta a funcionar!
            setAlertSuccess(true);
        }
    }

    return (
        <div className="requestContainer">
            <h1 className="requestTitle" tag="CSGS Grading Trading Card Request Certification" >{i18n.t('reqCer.title')}</h1>
            <form autoComplete="on" className="formRequest" onSubmit={handleSubmit}>
                <div className="numContainer">
                    <TextField style={{width:"35%"}} type="number" id="numCards" 
                        label={i18n.t('reqCer.ncards')}
                        variant="standard" 
                        name="numCards"
                        onChange={(e) => {
                                            if(e.target.value < 0) {e.target.value = 0} 
                                            setNumCards(e.target.value)

                                            let price = parseInt(e.target.value);
                                            if(subgrading) {
                                                price = price * (parseInt(subgradingPrice) + parseInt(cardPrice));
                                            } else {
                                                price = price * parseInt(cardPrice);
                                            }
                                            price = price + " CHF";
                                            setPriceTotal(price)
                                        }}
                        value={numCards}
                        required
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={subgrading}
                                onChange={handleChangeSubgrading}
                                name="subgrading"
                                color="primary"
                                value={subgrading}
                            />
                        }
                        label={i18n.t('reqCer.subgrading')}
                    />
                    <TextField
                        id="standard-read-only-input"
                        label={i18n.t('reqCer.price')}
                        name="price"
                        value={priceTotal}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </div>
                <div className="nameContainer">
                    <TextField id="standard-basic" 
                        label={i18n.t('reqCer.firstname')}
                        name="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                    />
                    <TextField id="standard-basic" 
                        label={i18n.t('reqCer.lastname')} 
                        name="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                    />
                </div>
                <TextField id="standard-basic" 
                    label={i18n.t('reqCer.address')} 
                    name="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                />
                <div className="postalContainer">
                    <TextField id="standard-basic" 
                        label={i18n.t('reqCer.zipcity')} 
                        name="cityZip"
                        value={cityZip}
                        onChange={(e) => setCityZip(e.target.value)}
                        required
                    />
                    <TextField id="standard-basic" 
                        label={i18n.t('reqCer.country')}  
                        name="country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        required
                    />
                </div>
                <div className="contactContainer">
                    <TextField id="standard-basic" label={i18n.t('reqCer.email')} 
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <MuiPhoneNumber defaultCountry={'ch'} 
                        onChange={handleOnChange}
                        name="phone"
                        label={i18n.t('reqCer.phonenumber')} 
                        defaultValue={phone}
                    />
                </div>
                <textarea id="standard-basic" label="Message" 
                    type="textarea"
                    name="msg"
                    onChange={(e) => setMsg(e.target.value)}
                    defaultValue={msg}
                />
                <div className="buttonContainer">
                    { !alertSuccess && <Button variant="contained" color="primary" name="btn" type="submit">{i18n.t('reqCer.btn')} </Button>}
                    { alertSuccess && <Button variant="contained" color="primary" name="btn" type="submit" disabled>{i18n.t('reqCer.btn')} </Button>}
                </div>
                <div className="buttonContainer">
                    { alertSuccess && <Alert severity="success" >{i18n.t('reqCer.alertsuccess')}</Alert>}
                    { alertError && <Alert severity="error" >{i18n.t('reqCer.alerterror')}</Alert>}
                </div>

            </form>
        </div>
    )
}

export default RequestCertification;