import React from 'react';
import { useState } from 'react';
import { TextField, Button } from '@material-ui/core'
import { Table } from 'react-bootstrap'
import Alert from '@material-ui/lab/Alert';
import "./VerificationCertification.css";
import axios from "axios";
import { i18n } from '../translate/translations';

export const VerificationCertification = () => {
    const [serialNumber, setSerialNumber] = useState('');
    const [alert, setAlert] = useState(false);
    const [alertDanger, setAlertDanger] = useState(false);
    const [card, setCard] = useState('');

    const getVerification = async(sn) => {
        console.log(sn);
        //var data = {"id": sn };

        var config = {
            method: 'get',
            url: process.env.REACT_APP_URL + '/api/certifications/id?data=' + sn,
            headers: { 
                'Content-Type': 'application/json'
            }
        };

        axios(config)
        .then(function (response) {
            console.log(response);
            if(response.data !== 0) {
                setCard(response.data)
            } else {
                console.log(response);
                setAlertDanger(true);
            }
            
        })
        .catch(function (error) {
            console.log(error);
        });
    } 

    const handleSubmit = (e) => {
        e.preventDefault();

        let serialnumber = e.target.serialnumber.value;

        setAlertDanger(false);

        if(!isNaN(serialnumber)) {
            setAlert(false);
            getVerification(serialnumber);
        } else {
            setAlert(true);
        }
    } 

    if(card !== '') {

        let new_card = card;
        delete new_card._id;

        return (

            <div className="verificationContainer" >
                <div className="formContainer" >
                    <h1 className="title" tag="CSGS Grading Trading Card Certification Verification" >{i18n.t('certVer.title1')}</h1>
                    <Table>
                        <tbody>
                            {
                                Object.keys(new_card).map( (key) => {
                                    let k = key;
                                    if(key.includes('_')) {
                                        k = key.replace('_', ' ');
                                    }

                                    return (
                                        <tr>
                                            <td style={{textAlign:"right", textTransform:"capitalize", fontWeight:"600"}}>{k}</td>
                                            <td>{new_card[key]}</td>
                                        </tr>   
                                    )
                                })
                            } 
                        </tbody>     
                    </Table> 
                </div>      
            </div>
        )

    } else {
        return (
            <div className="verificationContainer">
                <div className="formContainer" >
                    <h1 className="title" tag="CSGS Grading Trading Card Certification Verification" >{i18n.t('certVer.title2')}</h1>
                    <form autoComplete="on" onSubmit={handleSubmit}>
                        <TextField id="standard-basic" label={i18n.t('certVer.input')} 
                            className="inputSerial"
                            name="serialnumber"
                            value={serialNumber}
                            onChange={(e) => setSerialNumber(e.target.value)}
                            required
                            variant="filled"
                            color="primary"
                        />
                        <div className="buttonContainer">
                            <Button variant="contained" color="primary" type="submit">
                                {i18n.t('certVer.btn')} 
                            </Button>
                        </div>
                        <div className="buttonContainer">
                            { alert && <Alert severity="warning" className="alertVerification">{i18n.t('certVer.alert1')}</Alert>}
                            { alertDanger && <Alert severity="error" className="alertVerification">{i18n.t('certVer.alert2')}</Alert>}
                        </div>
                    </form>
                </div>
            </div>
            
        )
    }    
}

export default VerificationCertification;