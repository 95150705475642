import React from "react";
import "./Header.css"
import { Navbar, Container, Nav} from 'react-bootstrap';
import { i18n } from '../translate/translations'
import { useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const I18N_STORAGE_KEY = 'i18nextLng'

export const Header = () => {

    const [lng, setLng] = useState(window.localStorage.getItem(I18N_STORAGE_KEY));

    const changeLang = (e) => {
        setLng(e.target.value)
        window.localStorage.setItem(
            I18N_STORAGE_KEY,
            e.target.value
        )
        window.location = window.location;

    }

    return (
        <Navbar bg="dark" variant="dark" className="NavBar" style={{maxWidth:"100%"}}>
            <Container className="Container">
                <Navbar.Brand href="/" className="LogoContainer"><img className="Logo" alt="CSGS Grading Logo"></img> </Navbar.Brand>
                <Nav className="me-auto">
                    <Nav.Link href="/verificationcert" className="NavLink">{i18n.t('header.certVer')}</Nav.Link>
                    <Nav.Link href="/requestcert" className="NavLink">{i18n.t('header.reqCert')}</Nav.Link>
                    <Nav.Link href="/ourmission" className="NavLink">{i18n.t('header.whyUs')}</Nav.Link>
                </Nav>
                <Select
                    id="langSelector"
                    value={lng}
                    onChange={changeLang}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{backgroundColor:'white'}}
                >
                    <MenuItem value="en" className="itemLang">EN</MenuItem>
                    <MenuItem value="de" className="itemLang">DE</MenuItem>
                </Select>
            </Container>
        </Navbar>   
    )
}

export default Header;